// import {toast} from "react-toastify";
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    UPDATE_CART_DATA,
    CART_DELETE_ALL,
    CART_UPDATE_LANGUAGE,
    SET_POPUP,
    IS_READY_TO_ADD,
    RESET_IS_READY,
    CART_ADD_QUANTITIES

} from "./cartActionTypes";
import {url, apiUrlWithStore} from "../../helper";
import React from "react";
import {FormattedMessage} from "react-intl";
import {CheckToastSvg, FailSvg} from "svg";
import {runFbPixelEvent} from "../../services/utils";
import shopApi from "../../api/shop";
import {setPopup, setUpCrossProd} from "../general";


export function cartAddItemSuccessAfterLogin(
    product,
    options = [],
    quantity = 1,
    cartItems,
    locale
) {
    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
        cartItems,
    };
}

export function cartAddItemSuccess(
    product,
    options = [],
    quantity = 1,
    cartItems,
    locale
) {
    runFbPixelEvent({name: "Add To Cart"});
    let overWriteProductName = cartItems.items.map((item) => {
        if (item.product.id === product.id) {
            product.name = item.product?.name;
            return product;
        }
    });
    const newProduct = Object.assign({}, ...overWriteProductName);

    // toast.success(
    //     <span className="d-flex chek-fms">
    //   <CheckToastSvg/>
    //   <FormattedMessage
    //       id="add-cart"
    //       defaultMessage={`Product "${newProduct?.name ? newProduct?.name : ""
    //       }" added to cart`}
    //   />
    // </span>
    // );
    return {
        type: CART_ADD_ITEM,
        newProduct,
        options,
        quantity,
        cartItems,
    };
}

export function cartUpdateData(payload) {
    return {
        type: UPDATE_CART_DATA,
        payload,
    };
}

export function cartRemoveItemSuccess(itemId, item, cartData) {

    return {
        type: CART_REMOVE_ITEM,
        itemId,
        item,
        cartData,
    };
}

export function cartRemoveAllItems(quantities) {
    return {
        type: CART_DELETE_ALL,
    };
}

export function cartUpdateQuantitiesSuccess(payload) {
    return {
        type: CART_UPDATE_QUANTITIES,
        payload,
    };
}

export function cartUpdateLanguage(payload) {
    return {
        type: CART_UPDATE_LANGUAGE,
        payload,
    };
}


export function AddReady(payload) {
    return {
        type: RESET_IS_READY,
        payload,
    };
}

export function cartAddQuantities(payload) {
    return {
        type: CART_ADD_QUANTITIES,
        payload,
    };
}

export function resetAddReady(payload) {
    return {
        type: IS_READY_TO_ADD,
        payload,
    };
}

export function cartAddItemAfterLogin(
    product,
    options = [],
    quantity = 1,
    cartToken,
    customer,
    locale,
    dispatch,
    data
) {
    const as = () => {
        dispatch(
            cartAddItemSuccessAfterLogin(
                product,
                options,
                quantity,
                data.data,
                locale
            )
        );
    };
    return as();
}

export function cartAddItem(
    product,
    options = [],
    quantity ,
    cartToken,
    customer,
    locale,
    bundleProductInfo = null,
    pageFrom = ""
) {
    let body;

    if (customer && customer.token) {
        body = {
            api_token: cartToken.cartToken,
            product_id: product.id,
            // quantity: 1,
            // qty: 1,
            // token: customer.token,
            quantity: quantity,
            qty: quantity,
            token: customer.token,
        };
    } else {
        body = {
            api_token: cartToken.cartToken,
            product_id: product.id,
            quantity: quantity,
            qty: quantity,
        };
    }
    if (bundleProductInfo) {

        const {options, selectedOptions} = bundleProductInfo;
        const keys = selectedOptions && Object.keys(selectedOptions);
        const collection = keys
            .map((key) => {
                const filed = options.find((e) => e.type == key);
                if (filed) {
                    const arrayOfIds = selectedOptions[key].map((e) => e.id);
                    return {
                        [filed.id]: arrayOfIds,
                    };
                }
                return null;
            })
            .reduce((acc, next) => {
                const key = Object.keys(next);

                return {
                    ...acc,
                    [key]: next[key],
                };
            }, {});

        body['bundle_options'] = collection
        body['bundle_option_qty'] = {1: 1, 2: 1}
    }

    return (dispatch) =>
        fetch(apiUrlWithStore("/api/checkout/cart/add/" + `${pageFrom == "homePage" && product.id !== undefined
                ? product.id
                : product.id
            }`
            ),
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(body),
            }
        )
            .then((res) => res.json())
            .then((res) => {
                if (!res.error) {
                    dispatch(
                        cartAddItemSuccess(product, options, quantity, res.data, locale)
                    );
                    dispatch(AddReady(true))
                    dispatch(cartAddQuantities(1))
                } else {
                    if (body.quantity >= product.qty) {
                        //           toast(
                        //               <span className="d-flex faild-toast-fms">
                        //   <FailSvg/>
                        //   <FormattedMessage
                        //       id="sign-or-register"
                        //       defaultMessage="This product is not available"
                        //   />
                        // </span>
                        //           );
                    }
                }
            });
}

export function cartRemoveItemAfterLogin(itemId, item, dispatch) {
    return dispatch(cartRemoveItemSuccess(itemId, item));
}

//Create By Manvel
// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9ncmljaC5sb2NhbC56ZWdhZGV2LmNvbVwvYXBpXC9jdXN0b21lclwvbG9naW4iLCJpYXQiOjE2NjI3MjQ5MzIsImV4cCI6MTY2MjcyODUzMiwibmJmIjoxNjYyNzI0OTMyLCJqdGkiOiJKQzdTOXF4bm9sdEZjMGhoIiwic3ViIjoxLCJwcnYiOiI4ZmNhMDg4YWJhZTJmOWE4Zjg0YTVmMGJmNmE2NTI0NDkwNTViZTAwIn0.dlOFZc8s68Pqgxp_AOjinjKwmVJ4fNsHhFBp8TQEsZ4
export function cartTranslation(cartToken, customer, locale) {
    return (dispatch) =>
        shopApi.getCartTranslation(cartToken, customer, locale)
            .then((res) => {
                if (res.data) {
                    const {
                        grand_total,
                        items,
                        sub_total,
                        items_qty,
                    } = res.data;
                    const quantities = items.map((item) => {
                        return {
                            cartItem: 1,
                            itemId: item.id,
                            value: item.quantity,
                        };
                    });

                    let products;
                    if (items.length > 0) {
                        // shopApi.getIndicatorCartProducts(items).then(item => products = item)
                        dispatch({type: "REST_DATA", payload: items})
                    }

                    dispatch(
                        cartUpdateLanguage({
                            quantities,
                            grand_total,
                            items,
                            sub_total,
                            items_qty,
                        })
                    );
                } else {

                    dispatch(
                        cartUpdateLanguage({
                            quantities: [],
                            grand_total: "",
                            items: [],
                            sub_total: "",
                            items_qty: "",
                        })
                    );
                }
            });
}

export function cartRemoveItem(itemId, item, cartToken, customer) {
    return (dispatch) =>
        fetch(
            apiUrlWithStore(
                `/api/checkout/cart/remove-item/${itemId}?api_token=${cartToken.cartToken
                }${customer.token ? "&token=" + customer.token : ""}`
            )
        )
            .then((res) => res.json())
            .then((responce) => {
                return responce
                    ? dispatch(cartRemoveItemSuccess(itemId, item, responce.data))
                    : console.error(responce.error);
            })
            .catch((error) => console.error(error));
}

export function cartUpdateQuantities(
    quantities,
    cartItems,
    customerToken,
    ApiToken
) {
    let qty = {};
    let options;

    quantities.map((upitems, index) => {
        qty[cartItems[index].cartItemId] = upitems.value;
    });

    if (customerToken.token) {
        options = {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: customerToken.token,
                api_token: ApiToken.cartToken,
                qty: qty,
            }),
        };
    } else {
        options = {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                api_token: ApiToken.cartToken,
                qty: qty,
            }),
        };
    }
    return (dispatch) =>
        fetch(apiUrlWithStore("/api/checkout/cart/update"), options)
            .then((res) => res.json())
            .then((responce) => {
                if (responce.data) {
                    const {
                        grand_total,
                        items,
                        sub_total,
                        items_qty,
                    } = responce.data;

                    dispatch(
                        cartUpdateQuantitiesSuccess({
                            quantities,
                            grand_total,
                            items,
                            sub_total,
                            items_qty,
                        })
                    );
                } else {
                    dispatch(
                        cartUpdateQuantitiesSuccess({
                            quantities: [],
                            grand_total: "",
                            items: [],
                            sub_total: "",
                            items_qty: "",
                        })
                    );
                    console.error(responce.error);
                }
            })
            .catch((error) => console.error(error));
}


export const getUpOrCrossProd = (prodID, type, locale, currency, rate = 1, page = false) => dispatch => {
    switch (type) {
        case 'upSell':
            shopApi.getUpSellProducts(prodID, locale, currency, rate, page)
                .then(res => {
                    if (page) {
                        dispatch(setUpCrossProd(res))
                        dispatch(setPopup(true))
                    } else {
                        dispatch(setUpCrossProd(res))
                    }
                })
            break
        case 'crossSell':
            shopApi.getCrossSellProducts(prodID, locale, currency, rate, page)
                .then(res => {
                    if (page) {
                        dispatch(setUpCrossProd(res))
                        dispatch(setPopup(true))
                    } else {
                        dispatch(setUpCrossProd(res))
                    }
                })
            break
    }
}
