import shopApi from '../../api/shop';
import {QUICKVIEW_CLOSE, QUICKVIEW_OPEN} from './quickViewActionTypes';

let cancelPreviousRequest = () => {
};

export function quickviewOpenSuccess(product) {
    return {
        type: QUICKVIEW_OPEN,
        product,
    };
}

export function quickviewClose() {
    return {
        type: QUICKVIEW_CLOSE,
    };
}

export function quickViewOpen(productSlug) {
    return (dispatch) => {
        cancelPreviousRequest();

        return new Promise((resolve) => {
            let canceled = false;
            const timer = setTimeout(() => {
                shopApi.getProductBySlug({slug: productSlug}).then((product) => {
                    if (canceled) {
                        return;
                    }

                    if (product) {
                        dispatch(quickviewOpenSuccess(product));
                    }

                    resolve();
                });
            }, 350);

            cancelPreviousRequest = () => {
                canceled = true;
                clearTimeout(timer);
                resolve();
            };
        });
    };
}
