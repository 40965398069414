import {
    WALLET_ACCOUNT_CONNECTED,
    WALLET_ACCOUNT_DISCONNECT,
    MAX_APPROOVE_COUNT,
    GET_BUSD_BALANCE,
    CURRENT_BUSD_RATE
} from './walletActionTypes';

const initialState = {
    addressAccount: null,
    maxApprove: 0,
    busdBalance: 0,
    currentBusdrate: null
};

export default function walletconnectReducer(state = initialState, action) {
    switch (action.type) {
        case WALLET_ACCOUNT_CONNECTED:
            return {
                ...state,
                addressAccount: action.payload
            }
        case WALLET_ACCOUNT_DISCONNECT:
            return {
                ...state,
                addressAccount: null,
            }
        case MAX_APPROOVE_COUNT:
            return {
                ...state,
                maxApprove: action.payload
            }
        case GET_BUSD_BALANCE:
            return {
                ...state,
                busdBalance: action.payload
            }
        case CURRENT_BUSD_RATE:
            return {
                ...state,
                currentBusdrate: action.payload
            }
        default:
            return state;
    }
}