import {CHANGE_CURRENCY, SET_CURRENCY_LIST, GET_PAYMENT_CURRENCY} from "./currencyActionTypes";

// eslint-disable-next-line import/prefer-default-export
export const changeCurrency = (currency) => ({
    type: CHANGE_CURRENCY,
    payload: currency,
});

export const setCurrencies = (list) => ({
    type: SET_CURRENCY_LIST,
    payload: list,
});
export const setPaymentMethods = (paymentMethod) => ({
    type: GET_PAYMENT_CURRENCY,
    payload: paymentMethod
});