import {
    WALLET_ACCOUNT_CONNECTED,
    WALLET_ACCOUNT_DISCONNECT,
    MAX_APPROOVE_COUNT,
    GET_BUSD_BALANCE,
    CURRENT_BUSD_RATE
} from "./walletActionTypes"

export function connectedUserAccount(payload) {
    return {
        type: WALLET_ACCOUNT_CONNECTED,
        payload,
    };
}

export function disConnectedUserAccount(payload) {
    return {
        type: WALLET_ACCOUNT_DISCONNECT,
        payload,
    };
}

export function getMaxApproove(payload) {
    return {
        type: MAX_APPROOVE_COUNT,
        payload,
    };
}

export function getBUSDBlanace(payload) {
    return {
        type: GET_BUSD_BALANCE,
        payload,
    };
}

export function getCurrentBUSDrate(payload) {
    return {
        type: CURRENT_BUSD_RATE,
        payload,
    };
}