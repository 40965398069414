import { SET_SAME } from "./addressActionTypes";
import { IState } from "../../types/state";

const initialState = {
    same_address: true,
}

export default function addressReducer(state = initialState, action: { type: string; payload: IState; }) {
    switch (action.type) {
        case SET_SAME:
            return {
                ...state,
                same_address: action.payload,
            }
        default:
            return state
    }
}