import store from "../store";

export const url = {
    home: () => "/",

    catalog: () => "/catalog",

    category: (category) => {
        let stringQuery = `/catalog/${category.slug ? category.slug : category.department.slug}`
        if (category && category.query && category.query.page) {
            return `${stringQuery}?page=1`;
        }
        if (category && category.query && category.query.currencies) {
            return `${stringQuery}?currencies=${category.query}`;
        } else {
            return `${stringQuery}`;
        }
    },

    product: (product) => {
        if (product.product_flat && product?.product_flat.length > 0) {

            return `/${product.product_flat[0].url_key}`;
        } else {
            return `/${product.url_key}`;

        }


    },
}

export function convertStyles(settings) {
    let top = 0;
    let right = 0;
    let bottom = 0;
    let left = 0;
    let paddTop = 0;
    let paddRight = 0;
    let paddBottom = 0;
    let paddLeft = 0;
    let fontFamily = "";
    let image = [];
    let color = "";
    let width = "";
    let height = "initial";
    let align = "";
    let richEditorValue = "";
    let accordionTitle = "";
    let accordionDescription = "";
    let borderTopLeftRadius = "";
    let borderTopRightRadius = "";
    let borderBottomLeftRadius = "";
    let borderBottomRightRadius = "";
    let position = "";
    let zIndex = "";
    let positionLeft = "";
    let positionRight = "";
    let positionTop = "";
    let positionBottom = "";

    settings.map((item) => {
        Object.keys(item).forEach((key) => {
            if (key !== undefined) {
                switch (key) {
                    case "marginTop":
                        return (top = item.marginTop);
                    case "marginRight":
                        return (right = item.marginRight);
                    case "marginBottom":
                        return (bottom = item.marginBottom);
                    case "marginLeft":
                        return (left = item.marginLeft);
                    case "paddingTop":
                        return (paddTop = item.paddingTop);
                    case "paddingRight":
                        return (paddRight = item.paddingRight);
                    case "paddingBottom":
                        return (paddBottom = item.paddingBottom);
                    case "paddingLeft":
                        return (paddLeft = item.paddingLeft);
                    case "font-family":
                        return (fontFamily = item["font-family"]);
                    case "color":
                        return (color = item.color);
                    case "width":
                        return (width = item.width);
                    case "height":
                        return (height = item.height);

                    case "text-align":
                        return (align = item["text-align"]);
                    case "border-top-left-radius":
                        return (borderTopLeftRadius = item["border-top-left-radius"]);
                    case "border-top-right-radius":
                        return (borderTopRightRadius = item["border-top-right-radius"]);
                    case "border-bottom-left-radius":
                        return (borderBottomLeftRadius = item["border-bottom-left-radius"]);
                    case "border-bottom-right-radius":
                        return (borderBottomRightRadius = item["border-bottom-right-radius"]);
                    case "position":
                        return (position = item.position);
                    case "z-index":
                        return (zIndex = item["z-index"]);
                    case "left":
                        return (positionLeft = item.left);
                    case "right":
                        return (positionRight = item.right);
                    case "top":
                        return (positionTop = item.top);
                    case "bottom":
                        return (positionBottom = item.bottom);
                    default:
                        return false;
                }
            }
        });
    });
    const parentStyles = {
        ["text-align"]: `${align}`
    };

    const styles = {
        margin: `${top}px  ${right}px ${bottom}px ${left}px`,
        padding: `${paddTop}px ${paddRight}px ${paddBottom}px ${paddLeft}px`,
        ["font-family"]: `${fontFamily}`,
        color: color,
        width: `${width}px`,

        height: height ? `${height}px` : "unset",

        borderTopLeftRadius: `${borderTopLeftRadius}px`,
        borderTopRightRadius: `${borderTopRightRadius}px`,
        borderBottomLeftRadius: `${borderBottomLeftRadius}px`,
        borderBottomRightRadius: `${borderBottomRightRadius}px`,
        position: position,
        zIndex: `${zIndex}px`,
        left: `${positionLeft}px`,
        right: `${positionRight}px`,
        top: `${positionTop}px`,
        bottom: `${positionBottom}px`,

    };
    return {styles, parentStyles}
}

export function getCategoryParents(category) {
    return category.parent
        ? [...getCategoryParents(category.parent), category.parent]
        : [];
}

export function runFbPixelEvent(eventData) {
    const {
        general: {fbPixel},
    } = store.getState();
    setTimeout(() => {
        if (fbPixel) {
            typeof window != undefined ? fbq("track", eventData?.name) : null;
        }
    }, 500);
}


export function removeCurrencyTemp(total, symbol, locale, rate) {
    if (total) {
        let convertSymbols = total
            .toString()
            .replace("$", "")
            .replace(",", "")
            .replace("֏", "");
        let result = convertSymbols.replace(/\s/g, "");
        return `${locale === "hy" ? (Number(result) * rate) + " " + symbol : symbol + " " + (Number(result) * rate)} `;
    }
}


const replacer = (string, filterName) => {
    return string.replace(string.substring(string.indexOf(filterName), string.includes("&") ? string.indexOf("&") + 1 : string.length), "")
}

export const rightUrl = (path, router) => {
    if (router.asPath.includes("?")) {
        let x = `${path}${router.asPath.slice(router.asPath.indexOf("?"))}`
        let y = replacer(clearQueryPath(x), "page");
        return y.indexOf("?") === y.length - 1 ? y.slice(0, y.length - 1) : y
    }
    return path
}

const clearQueryPath = (string) => {
    let ab;
    if (string.includes("filter_")) {
        ab = replacer(string, "filter")
        if (ab.includes("filter_")) {
            return clearQueryPath(ab)
        }
        return ab
    }
    return string
}


export const getSeparateDomain = (dbName) => {
    let databaseName;
    if (dbName.includes(process.env.APP_SUFFIX)) {
        var dataName = dbName.split(process.env.APP_SUFFIX);
        databaseName = dataName[0];
        process.env.domainName = dbName;
        process.env.databaseName = databaseName;
    } else {
        process.env.domainName = dbName;
        databaseName = dbName.split(".")[0] === "www" ? dbName.split(".")[1] : dbName.split(".")[0];

        process.env.databaseName = databaseName;
    }
    return databaseName
}


export function RemoveHTMLTags(html) {
    let regX = /(<([^>]+)>)/ig;
    return html.replace(regX, "");
}


export const ValidateEmail = (value) => {
    const validEmailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!validEmailRegExp.test(value)) {
        return <div style={{color: "red"}}>your email is not valid, please fill it correctly!</div>
    }
}

export const replaceDescr = (str) => {
    if (str) {
        let obj = {}
        let newStr = str
        while (newStr.includes("<table")) {
            let start = newStr.lastIndexOf("<table")
            let end = newStr.lastIndexOf("</table>") + 8

            obj[newStr.slice(start, end)] = [start, end]

            newStr = newStr.replace(newStr.slice(start, end), '')
        }
        for (let key in obj) {
            let start = obj[key][0]
            let end = obj[key][1]
            let value = `<div className="table_wrapper" style="overflow: scroll">${key}</div>`
            str = str.replace(str.slice(start, end), value)
        }
        return str
    }
}

export const idGen = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export const sortSliderImages = (arr, keyName) => {
    return arr?.map(image => {
        return {
            path: image[keyName],
            slider_path: image.slider_path,
            content: image.content,
            text: image.text
        }
    })
}

