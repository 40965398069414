import {WEB3_GET_PROVIDER, WEB3_NULL_PROVIDER} from './providerActionsTypes';


const initialState = {
    providerState: null,
};

export default function getProviderReducer(state = initialState, action) {
    switch (action.type) {
        case WEB3_GET_PROVIDER:
            return {
                ...state,
                providerState: action.payload
            }
        case WEB3_NULL_PROVIDER:
            return {
                ...state,
                providerState: null,
            }
        default:
            return state;
    }
}